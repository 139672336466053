.unitItem {
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
  padding: 4px 0;
  min-height: 50px;
  cursor: pointer;
  transition: background-color 0.5s, color 0.5s, opacity 0.5s;
  background-color: #fff;
}

.unitItem:hover {
  background-color: #e3ebff;
}

.unitItemIsDrilledDown {
  background-color: #10182c;
  color: white;
}

.unitItemIsDrilledDown:hover {
  /* background-color: #10182c; */
  /* opacity: 0.8; */
  background-color: rgba(16, 24, 44, 0.9);
  color: white;
}

.actionArea {
  background-color: #f6f6f6;
  margin-top: -1px;
  border-bottom: 1px solid #e0e0e0;
  font-size: 14px;
  display: flex;
  justify-content: right;
  gap: 36px;
  padding: 0 16px;
  height: 24px;
  user-select: none;
}

.selectAllChildren {
  color: #4086f4;
  cursor: pointer;
}

.unselectAllChildren {
  color: #ff4545;
  cursor: pointer;
}

.unselectAllChildren:hover,
.selectAllChildren:hover {
  text-decoration: underline;
}

.unitItem.notCompatible {
  cursor: default;
}

.unitItem.onboarded {
  cursor: default;
}

.clickableArea {
  padding-top: 4px;
  padding-bottom: 4px;
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.unitItemNotCompatible {
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
  padding: 6px 0;
  background-color: #e7e7e7;
  color: #646464;
}

.unitItemHasError {
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
  padding: 6px 0;
  background-color: #e7e7e7;
  /* color: red; */
  cursor: not-allowed;
}

.unitItemOnboarded {
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
  padding: 6px 0;
  background-color: #fff4ea;
  color: #3f3f3f;
}

.checkboxWrapper {
  width: 45px;
  text-align: center;
}

.checkbox {
  color: #fff;
  background-color: #fff;
  border: 1px solid gray;
  border-radius: 4px;
  font-size: 0.8rem;
  padding: 2px 5px;
  width: 24px;
  height: 24px;
}

.checkbox.selected {
  color: #fff;
  background-color: #ff5722;
  border: none;
  font-size: 0.8rem;
  padding: 2px 5px;
  width: 24px;
  height: 24px;
}

.checkbox.isOnboarded {
  color: #fff;
  background-color: #ffd2c8;
  border: none;
  border-radius: 4px;
  font-size: 0.8rem;
  padding: 2px 5px;
  width: 24px;
  height: 24px;
}

.checkbox.allChildNotCompatible {
  color: #bdbdbd;
  background-color: #bdbdbd;
  border: none;
  border-radius: 4px;
  font-size: 0.8rem;
  padding: 2px 5px;
  width: 24px;
  height: 24px;
}

.checkbox.isParent {
  color: #fff;
  background-color: rgb(255, 255, 255);
  border-radius: 4px;
  font-size: 0.8rem;
  padding: 2px 5px;
  width: 24px;
  height: 24px;
}

.checkbox.partiallySelected {
  color: #fff;
  background-color: rgb(255, 255, 255);
  border-radius: 4px;
  padding: 2px 5px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkboxDot {
  width: 6px;
  height: 6px;
  background-color: #fd7355;
  border-radius: 6px;
}

.itemName {
  flex: 1;
  padding-right: 8px;
  line-height: 1.1;
  font-size: 1rem;

  word-break: break-all;
  width: 256px;
}

.itemStat {
  text-align: right;
  font-size: 0.85rem;
  font-weight: 500;
  line-height: 1.2rem;

  margin-top: -6px;
}

.arrowWrapper {
  width: 75px;
  margin-left: 4px;
  margin-right: 4px;
  /* border: 1px solid red; */
  text-align: center;
  display: flex;
  justify-content: center;
}

.arrowIcon {
  width: 30px;
  /* border: 1px solid blue; */
}

.childStatusWrapper {
  width: 25px;
  /* border: 1px solid green; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.childStatusIconCircle {
  background-color: #f6f6f6;
  border-radius: 100px;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circleColorLight {
  background-color: #f6f6f6;
}

.circleColorLight.isDrilledDown {
  background-color: #3f4450;
}

.circleColorSolid {
  background-color: #ff896e;
}

.circleColorSolid.isDrilledDown {
  background-color: #ff896e;
}

.childStatusIcon {
  color: #fd7355;
  margin-bottom: 2px;
}

.iconColorWhite {
  color: #fff;
}

.iconColorSolidOrange {
  /* color: #fd7355; */
  color: #ff896e;
  /* color: palegreen; */
}

.iconColorLightOrange {
  color: #fc9a85;
}

.iconColorLightGray {
  color: #c2c0c0;
}

.statBarWrapper {
  width: 80px;
  height: 4px;
  background-color: lightgray;
  position: relative;
  text-align: right;
  float: right;
}

.notCompatibleText {
  font-size: 12px;
  color: #7c7c7c;
  font-weight: 400;
  padding: 1px 4px;
  background-color: #e9e9e9;
  border-radius: 4px;
}

.gptBar {
  height: 4px;
  background-color: skyblue;
  position: absolute;
  left: 0;
}

.ampBar {
  height: 4px;
  background-color: orange;
  position: absolute;
}

.statIndicator {
  background-color: #969696;
  height: 10px;
  width: 1px;
  position: absolute;
  top: -3px;
}

/* Selected Items Table */
.selectedRow {
  border-bottom: 1px solid gray;
  padding: 10px 16px;

  /* line height to overwrite 1.5 set in body in common.css */
  line-height: 1.2;
  font-size: 16px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  min-height: 50px;
  background-color: #fff;
}

.selectedRowStart {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.unitExtId {
  font-size: 14px;
  color: #595959;
}

.unitName {
  font-size: 16px;
}

.parentLabel {
  font-size: 10px;
  font-weight: "500";
  text-transform: uppercase;
  padding: 1px 4px;
  border-radius: 20px;
  margin-top: 0px;
  height: 16px;
  width: 16px;
  margin-left: 4px;
  margin-right: 4px;

  background-color: #fed8bf;
  color: #af4c1e;

  display: flex;
  align-items: baseline;
  justify-content: flex-end;

  line-height: 1.5;
}

.mcmLabel {
  /* line height to overwrite 1.5 set in body in common.css */
  line-height: normal;

  background-color: #bfdbfe;
  color: #1e40af;
  padding: 2px 4px;
  font-size: 12px;
  font-weight: 500;
  text-decoration: none;
  border-radius: 4px;
}

.showMoreItemsBtn {
  font-size: 1rem;
  font-weight: 500;
  padding: 16px 58px;
  width: 100%;
  display: flex;
  color: rgb(77, 124, 252);
  border-top: 1px solid rgb(77, 124, 252);
  border-bottom: none;
  border-left: none;
  border-right: none;
  background-color: #ecf8ff;
  cursor: pointer;
  text-align: left;
}

.showMoreItemsBtn:hover {
  background-color: #f1faff;
}

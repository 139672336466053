.selectedAdUnits {
  height: 504px;
  overflow-y: auto;
  overflow-x: hidden;
  border: 1px solid #9a9a9a;
  background: rgba(255, 255, 255, 0.4);
}

div.nameWrapper {
  padding: 6px 0px;
  padding-left: 0;
  padding-right: 0;
  text-align: left;
}

.loadingOverlayWrapper {
  position: relative;
}
.loadingInsideOverlay {
  z-index: 999999;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #fff;
  opacity: 0.8;
  border: 1px solid gray;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: not-allowed;
}

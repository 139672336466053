.treeSelector {
  margin-bottom: 0px;
  border-top: 0;
}

.treeSelector.invalid {
  border: 1px solid #e80000;
}

.treeSelector .node {
  text-align: left;
  cursor: pointer;
  transition: background-color 0.5s, color 0.5s, opacity 0.5s;
  /* padding-left: 15px;
  padding-right: 15px; */
  margin-right: 0;
  margin-left: 0;
}

.treeSelector .node > div {
  padding-right: 0;
  padding-left: 0;
}

.treeSelector button {
  display: inline;
  align-items: flex-start;
}

.node {
  overflow-x: hidden;
  word-break: break-all;
}

.nodeNotCompatible {
  overflow-x: hidden;
  word-break: break-all;
  cursor: not-allowed;
}

.nodeOnboarded {
  overflow-x: hidden;
  word-break: break-all;
  cursor: default;
}

.node:hover {
  /* background-color: #10182c;
  color: white; */

  background-color: #dfe8ff;
}

.treeSelector .node.opened:hover,
.treeSelector .node.selected:hover {
  opacity: 0.8;
}

.treeSelector .node.selected {
  background-color: #ff5722;
  color: white;
}

.treeSelector .node.opened {
  background-color: #10182c !important;
  color: white;

  /* background-color: #b5c6ff; */
}

.noItems {
  pointer-events: none;
}

.treeSelector .tableColumn {
  border-right: 1px solid #9a9a9a;
  padding-right: 0;
  padding-left: 0;
}

.treeSelector .tableColumn:last-child {
  border-right: 0;
}

.branchIcon {
  font-size: 14px;
}

.treeSelector .itemList {
  border-top: 1px solid #9a9a9a;
  max-height: 300px;
  overflow-y: auto;
}

.treeSelector .itemList.itemListShort {
  max-height: 150px;
}

.treeSelector .columnHeader .seachInputWrapper {
  border-left: 1px solid #9a9a9a;
  flex: 1;
}

.treeSelector .columnHeader .seachInputWrapper input {
  border: none;
}

.treeSelector .columnHeader {
  display: flex;
  align-items: center;
}

.treeSelector .columnHeader .checkAllWrapper {
  margin-bottom: 2px;
  margin-left: 2px;
  margin-right: 2px;
}

.fiveColumns {
  background-color: red;
}

.columns5 > a {
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
}

.columns7 > a {
  -ms-flex: 0 0 14.2857142%;
  flex: 0 0 14.2857142%;
  max-width: 14.2857142%;
}

.columns8 > a {
  -ms-flex: 0 0 12.5%;
  flex: 0 0 12.5%;
  max-width: 12.5%;
}

.columns9 > a {
  -ms-flex: 0 0 11.11111111111111%;
  flex: 0 0 11.11111111111111%;
  max-width: 11.11111111111111%;
}

.columns10 > a {
  -ms-flex: 0 0 10%;
  flex: 0 0 10%;
  max-width: 10%;
}

.columns11 > a {
  -ms-flex: 0 0 9.0909%;
  flex: 0 0 9.0909%;
  max-width: 9.0909%;
}

.columns12 > a {
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.password-eye-container {
  position: relative;
}

.eye-icon {
  position: absolute;
  cursor: pointer;
  color: rgb(92, 92, 92);
  top: 24%;
  right: 2%;
}

.eye-icon:hover {
  color: rgb(32, 32, 32);
}

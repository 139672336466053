.form {
  padding: 15px;
}

.form.formNoPadding {
  padding: 0px;
}

.requiredStar {
  color: #e80000;
  font-weight: 700;
}

.form label {
  cursor: pointer;
}

.label {
  text-align: left;
  font-size: 1.5rem;
}

.form select,
.form input[type="number"],
.form input[type="email"],
.form input[type="text"] {
  height: 40px;
  padding: 14px 0px 14px 12px;
  width: 100%;
  -webkit-border-radius: 0px;
  border-width: 1px;
  margin: 0;
  vertical-align: inherit;
  border-color: #9a9a9a;
  background-color: #eeeeee;
  box-sizing: border-box;
}

.form .currencyWrapper {
  position: relative;
}

.form .currencyWrapper .currency {
  position: absolute;
  font-weight: bold;
  text-align: right;
  overflow: hidden;
  height: 100%;
  left: 5px;
  top: 7px;
  width: 36px;
}

.form .currencyWrapper input[type="number"] {
  padding-left: 44px;
}

.form .inputRadio {
  font-size: 22px;
  margin-right: 15px;
  cursor: pointer;
}

.form input[type="checkbox"],
.form input[type="radio"] {
  height: 18px;
  width: 18px;
}

.form.submitted select:invalid,
.form.submitted input[type="text"]:invalid,
.form.submitted input[type="email"]:invalid {
  border: 1px solid #e80000;
}

.invalid select {
  border: 1px solid #e80000;
}

.form select {
  cursor: pointer;
  padding: 0px;
}

.form select + select {
  border-top: 0;
}

.row {
  padding: 10px;
}

.bigIcon {
  color: #ff5722;
}

.submitBtnLoader {
  float: right;
  margin: 10px 10px 0px 0px;
}

.hiddenInput {
  pointer-events: none;
  width: 100%;
  height: 0px;
  opacity: 0;
}

.cancelBtn:disabled,
.cancelBtn[disabled],
.crossBtn:disabled,
.crossBtn[disabled],
.submitModalBtn:disabled,
.submitModalBtn[disabled] {
  opacity: 0.8;
  pointer-events: none;
}

.submitLoading,
.cancelBtn,
.secondaryBtn,
.submitModalBtn,
.submitBtn {
  font-size: 16px;
  float: right;
  cursor: pointer;
  background-color: #ff5722;
  border: none;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  letter-spacing: 0.03em;
  color: white;
  box-sizing: border-box;
  height: 32px;
  line-height: 32px;
  padding: 0 18px;
  display: inline-block;
  margin: 10px 10px 0px 0px;
  transition: opacity 0.23s ease-in-out 0s,
    background-color 0.23s ease-in-out 0s;
}

.secondaryBtn,
.submitLoading,
.submitBtn {
  position: relative;
  top: 15px;
}

.secondaryBtn,
.submitLoading,
.submitBtn {
  position: relative;
  top: 15px;
}

.submitBtn:disabled,
.submitBtn[disabled] {
  opacity: 0.5;
  pointer-events: none;
}

button.secondaryBtn,
button.cancelBtn {
  background-color: #10182c;
}

.secondaryBtn:hover,
.cancelBtn:hover {
  background-color: #ff5722;
}

.submitBtn:hover,
.submitModalBtn:hover {
  opacity: 0.8;
}

.submitLoading {
  padding-left: 24px;
  padding-right: 24px;
  background-color: transparent;
}

.inputGroup {
  position: relative;
}

.inputGroupLeftSelect {
  padding-left: 100px;
}

.inputGroupLeftButton {
  position: relative;
  padding-left: 40px;
}

.inputGroupRightButtonExtra,
.inputGroupRightButton {
  position: relative;
  padding-right: 40px;
}

.inputGroupRightButtonExtra {
  padding-right: 80px;
}

.inputGroup .clearButton {
  position: absolute;
  top: 7px;
  right: 10px;
  font-size: 18px;
  cursor: pointer;
  display: none;
}

.inputGroupLeftSelect .leftPrefix,
.inputGroupLeftSelect .leftSelect {
  display: inline-block;
  position: absolute;
  width: 100px;
  left: 0;
}

.inputGroupLeftSelect .leftPrefix {
  pointer-events: none;
  width: 183px;
  text-align: center;
  line-height: 40px;
}

.inputGroupLeftButton .leftButtonWrapper {
  display: inline-block;
  position: absolute;
  left: 0;
}

.inputGroupRightButtonExtra .rightButtonWrapper {
  display: inline-block;
  position: absolute;
  right: 40px;
}

.inputGroupRightButtonExtra .rightButtonExtraWrapper,
.inputGroupRightButton .rightButtonWrapper {
  display: inline-block;
  position: absolute;
  right: 0;
}

.inputGroupRightButtonExtra .rightButtonExtraWrapper span {
  margin: 0;
}

.inputGroupRightButtonExtra button,
.inputGroupRightButton button,
.inputGroupLeftButton button {
  width: 40px;
  height: 40px;
  border: 1px solid #9a9a9a;
  margin: 0;
}

.inputGroup .clearButton:hover {
  color: #ff5722;
}

.inputGroup .clearButton.clearButtonVisible {
  display: inline;
}
